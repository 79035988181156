import { FC, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';

import emptyAvatar from 'assets/images/png/emptyAvatar.png';
import { useGetUserInfoQuery } from 'hooks/queries';
import { useDeleteUserMutation } from 'hooks/user';
import { sortByMainRole } from 'utils';
import { USER_PAGE_OPTIONS } from '../../const';
import styles from './Sidebar.module.css';

import ConfirmationModal from 'components/atoms/ConfirmationModal';
import Modal from 'components/atoms/Modal';
import { IconBack, IconPen, IconTrash, IconUpload } from 'components/atoms/icons';
import { SelectAvatarForm } from '..';
import SkeletonSidebar from './SkeletonSidebar';

type IParams = {
  id: string;
};

const Sidebar: FC = () => {
  const navigate = useNavigate();
  const { id: userId } = useParams<keyof IParams>() as IParams;
  const { data: user, isLoading: userLoading } = useGetUserInfoQuery(userId);
  const [avatarModalActive, setAvatarModalActive] = useState(false);
  const [deleteModalActive, setDeleteModalActive] = useState(false);
  const { mutate: deleteUserMutate, isLoading: deleteUserLoading } = useDeleteUserMutation();

  const deleteUser = () => {
    if (!user) return;
    deleteUserMutate(user.id, { onSuccess: () => navigate('/users') });
  };

  if (userLoading) return <SkeletonSidebar />;
  if (!user) return <h2>Не удалось загрузить данные пользователя</h2>;

  return (
    <div className={styles.info}>
      <button
        className='mb-[30px] flex h-[28px] select-none items-center self-start'
        onClick={() => navigate(-1)}
      >
        <IconBack className='text-[#71798F]' />
        <p className='text-[14px] font-[500] leading-[16px] text-[#71798F]'>Назад</p>
      </button>

      <label className={styles.image} onClick={() => setAvatarModalActive(true)}>
        <img src={user.avatarUrl || emptyAvatar} alt='' />
        <div className={styles.uploadIcon}>
          <IconUpload className='h-[24px] w-[24px]' />
        </div>
      </label>

      <p className={styles.name} title={user.name}>
        {user.name}
      </p>

      <p className={styles.role}>{sortByMainRole(user.roles).join(', ')}</p>

      <p className={styles.email} title={user.email}>
        {user.email}
      </p>

      <div className={styles.sidebar__options}>
        <NavLink
          to={`/users/${user.id}/statistics`}
          className={({ isActive }) =>
            isActive
              ? `${styles.sidebar__option} ${styles.sidebar__option_active}`
              : styles.sidebar__option
          }
        >
          {USER_PAGE_OPTIONS.STATISTICS}
        </NavLink>
        <NavLink
          to={`/users/${user.id}/subscriptions`}
          className={({ isActive }) =>
            isActive
              ? `${styles.sidebar__option} ${styles.sidebar__option_active}`
              : styles.sidebar__option
          }
        >
          {USER_PAGE_OPTIONS.SUBSCRIPTIONS}
        </NavLink>
        <NavLink
          to={`/users/${user.id}/ai-teacher`}
          className={({ isActive }) =>
            isActive
              ? `${styles.sidebar__option} ${styles.sidebar__option_active}`
              : styles.sidebar__option
          }
        >
          {USER_PAGE_OPTIONS.AITEACHER}
        </NavLink>
      </div>

      <NavLink
        to={`/users/${user.id}/edit`}
        className={({ isActive }) =>
          isActive ? `${styles.editButton} ${styles.editButton_active}` : styles.editButton
        }
      >
        <IconPen className={styles.editIcon} />
        <span className={styles.editText}>{USER_PAGE_OPTIONS.EDIT}</span>
      </NavLink>

      <div className='mb-[8px]'>
        <span className=' mr-[9px] text-[14px] font-[400] leading-[16px] text-[#71798F]'>
          Дата регистрации:
        </span>

        <span className='text-[16px] font-[500] leading-[19px] text-[#20233A]'>
          {new Date(user.createdAt).toLocaleDateString('en-GB')}
        </span>
      </div>

      <div className='mb-[24px]'>
        <span className=' mr-[9px] text-[14px] font-[400] leading-[16px] text-[#71798F]'>ID:</span>
        <span className='text-[16px] font-[500] leading-[19px] text-[#20233A]'>{user.id}</span>
      </div>

      <button
        type='button'
        className='flex h-[28px] items-center gap-[8px]'
        onClick={() => setDeleteModalActive(true)}
      >
        <span className='text-[16px] font-[500] leading-[19px] text-[#A1AABC]'>
          Удалить пользователя
        </span>
        <IconTrash color='#A1AABC' className='h-[24px] w-[24px]' />
      </button>

      {avatarModalActive && (
        <Modal onClose={() => setAvatarModalActive(false)}>
          <SelectAvatarForm onClose={() => setAvatarModalActive(false)} />
        </Modal>
      )}

      {deleteModalActive && (
        <ConfirmationModal
          title={
            <>
              Удалить пользователя <br />
              {user.name}?
            </>
          }
          text={
            <>
              Пользователь будет удален <b>навсегда</b> и <b>безвозвратно</b>
            </>
          }
          isDelete
          confirmButtonText='Удалить'
          onConfirm={deleteUser}
          onClose={() => setDeleteModalActive(false)}
          isLoading={deleteUserLoading}
        />
      )}
    </div>
  );
};

export default Sidebar;
