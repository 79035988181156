import { useSearchParams } from 'react-router-dom';
import { SEARCH_PARAMS } from 'pages/DealNotificationsPage/const';
import { Pagination } from 'models';
import styles from './BalanceHistoryPagination.module.css';
import BePagination from 'components/molecules/BePagination/BePagination';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../../../model';

type BalanceHistoryPaginationProps = {
  pagination: Pagination | undefined;
  usersPerPage: number;
};

export const BalanceHistoryPagination: React.FC<BalanceHistoryPaginationProps> = ({
  pagination,
  usersPerPage
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = parseInt(
    searchParams.get(SEARCH_PARAMS.PAGE) || String(DEFAULT_PAGE),
    DEFAULT_PER_PAGE
  );

  const handlePageChange = (page: number) => {
    searchParams.set(SEARCH_PARAMS.PAGE, String(page));
    setSearchParams(searchParams);
  };

  return (
    <div className={styles.table__pagination}>
      {pagination && pagination.totalItems > usersPerPage && (
        <BePagination key={currentPage} pagination={pagination} setCurrentPage={handlePageChange} />
      )}
    </div>
  );
};
