import { BalanceOperationType, IBalanceHistoryResponse } from 'api';
import { AI_TEACHER_USER_TABLE_HEADERS, DEFAULT_PER_PAGE } from '../../../model';
import styles from './BalanceHistory.module.css';
import { SkeletonRows } from 'pages/UsersPage/components';
import { FC } from 'react';
import { BalanceHistoryPagination } from '../../BalanceHistoryPagination';

type BalanceHistoryProps = {
  userBalanceHistoryData: IBalanceHistoryResponse | undefined;
  isLoading: boolean;
};

export const BalanceHistory: FC<BalanceHistoryProps> = props => {
  const { userBalanceHistoryData, isLoading } = props;

  return (
    <div className={styles.table}>
      <div className={styles.table__headers}>
        <span className={`${styles.table__header} ${styles.table__header_replenished}`}>
          {AI_TEACHER_USER_TABLE_HEADERS[0]}
        </span>

        <span className={`${styles.table__header} ${styles.table__header_totalCoins}`}>
          {AI_TEACHER_USER_TABLE_HEADERS[1]}
        </span>
        <span className={`${styles.table__header} ${styles.table__header_date}`}>
          {AI_TEACHER_USER_TABLE_HEADERS[2]}
        </span>
      </div>

      <div className={styles.table__rows}>
        {userBalanceHistoryData && !isLoading ? (
          <>
            {userBalanceHistoryData.data.length > 0 ? (
              <>
                {userBalanceHistoryData.data.map((user, i) => {
                  return (
                    <div
                      className={`${styles.table__row} ${
                        i % 2 === 1 ? styles.table__row_even : ''
                      }`}
                      key={i}
                    >
                      <button
                        type='button'
                        className={`${styles.table__cell} ${styles.table__cell_replenished}`}
                      >
                        <span>
                          {user.balanceOperationType === BalanceOperationType.REPLENISHED
                            ? 'Пополнение баланса'
                            : 'Использование монет'}
                        </span>
                      </button>

                      <div className={`${styles.table__cell} ${styles.table__cell_totalCoins}`}>
                        <span
                          style={{
                            color:
                              user.totalCoinsReplenished > 0
                                ? '#66C84D'
                                : user.totalCoinsReplenished < 0
                                ? '#71798F'
                                : '#71798F'
                          }}
                        >
                          {user.balanceOperationType === BalanceOperationType.REPLENISHED
                            ? '+' + user.totalCoinsReplenished
                            : user.totalCoinsSpent}
                        </span>
                      </div>

                      <div
                        title={new Date(user.date).toLocaleString()}
                        className={`${styles.table__cell} ${styles.table__cell_date}`}
                      >
                        <span>
                          {new Intl.DateTimeFormat('ru', {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit'
                          }).format(new Date(user.date))}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <div className='py-8 text-center'>
                <span>Операций не найдено</span>
              </div>
            )}
          </>
        ) : (
          <SkeletonRows />
        )}
      </div>

      {userBalanceHistoryData &&
        userBalanceHistoryData.pagination.totalItems > DEFAULT_PER_PAGE && (
          <BalanceHistoryPagination
            pagination={userBalanceHistoryData?.pagination}
            usersPerPage={DEFAULT_PER_PAGE}
          />
        )}
    </div>
  );
};
