import React, { SVGProps } from 'react';

const IconZiggurat: React.FC<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <rect width='24' height='24' rx='12' fill='#FFC530' />
      <path
        d='M12 7.5L11.044 10.4065C10.9951 10.5553 10.9119 10.6904 10.8012 10.8012C10.6904 10.9119 10.5553 10.9951 10.4065 11.044L7.5 12L10.4065 12.956C10.5553 13.0049 10.6904 13.0881 10.8012 13.1988C10.9119 13.3096 10.9951 13.4447 11.044 13.5935L12 16.5L12.956 13.5935C13.0049 13.4447 13.0881 13.3096 13.1988 13.1988C13.3096 13.0881 13.4447 13.0049 13.5935 12.956L16.5 12L13.5935 11.044C13.4447 10.9951 13.3096 10.9119 13.1988 10.8012C13.0881 10.6904 13.0049 10.5553 12.956 10.4065L12 7.5Z'
        fill='white'
        stroke='white'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.5 7.5V9.5M15.5 14.5V16.5M7.5 8.5H9.5M14.5 15.5H16.5'
        stroke='white'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default IconZiggurat;
