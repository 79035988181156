/* eslint-disable react-hooks/exhaustive-deps */

import { FC, useState, useEffect } from 'react';

import styles from './ImageUploadForm.module.css';
import { formatBytes, resizeImage } from 'utils';

import { IconClose, IconUpload } from 'components/atoms/icons';
import CourseImgUpload from 'components/molecules/CourseImgUpload';
import Button from 'components/atoms/Button';

interface ImageUploadFormProps {
  image?: File | null;
  imageUrl?: string | null;
  isSquare?: boolean;
  setImage: (image: File) => void;
  onCancel: () => void;
}

const ImageUploadForm: FC<ImageUploadFormProps> = ({
  image,
  imageUrl,
  setImage,
  onCancel,
  isSquare = false
}) => {
  const [currentImage, setCurrentImage] = useState<File | null>(image || null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string | null>(imageUrl || null);
  const [imageName, setImageName] = useState<string | null>(null);
  const [imageSize, setImageSize] = useState<string | null>(null);

  useEffect(() => {
    if (!currentImage) {
      return;
    }

    const objectUrl = URL.createObjectURL(currentImage);
    setImagePreviewUrl(objectUrl);

    setImageName(currentImage.name);
    setImageSize(formatBytes(currentImage.size));

    return () => URL.revokeObjectURL(objectUrl);
  }, [currentImage]);

  const handleAdd = async () => {
    if (!currentImage) {
      return;
    }

    setImage(currentImage);
    onCancel();
  };

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <span className={styles.title}>Загрузить картинку</span>

        <button className={styles.buttonClose} onClick={onCancel}>
          <IconClose color='#71798F' />
        </button>
      </header>

      <div className={`${styles.upload} ${currentImage ? styles.upload_active : ''}`}>
        <CourseImgUpload
          onChange={file => {
            if (file) {
              resizeImage(
                file,
                (resizedFile, previewUrl) => {
                  setCurrentImage(resizedFile);
                  setImagePreviewUrl(previewUrl);
                },
                isSquare
              );
            }
          }}
          accept={'.png, .jpg, .jpeg'}
          cover={imagePreviewUrl || ''}
          className={`relative flex items-center justify-center overflow-hidden rounded-[12px] ${
            currentImage
              ? 'h-[200px] w-[200px] rounded-[16px] border-[1px] border-dashed border-[#E9ECF0]'
              : 'h-[64px] w-[64px] bg-[#EFF1F4]'
          }`}
        >
          {!imagePreviewUrl && (
            <div className='flex flex-col items-start justify-start'>
              <IconUpload color='#5770F3' />
            </div>
          )}
        </CourseImgUpload>

        {currentImage ? (
          <div className={styles.upload__info}>
            <p>{imageName}</p>
            <span>{imageSize}</span>
          </div>
        ) : (
          <div className={styles.upload__text}>
            <h4>Загрузить картинку</h4>
            <p>Файл формата PNG или JPG</p>
          </div>
        )}
      </div>

      <div className={styles.buttons}>
        <Button
          type='reset'
          variant='secondary'
          title='Отменить'
          className='w-[198px]'
          onClick={onCancel}
        />

        <Button
          type='submit'
          variant='primary'
          title='Добавить'
          className='w-[198px]'
          onClick={handleAdd}
          isDisabled={!currentImage}
        />
      </div>
    </div>
  );
};

export default ImageUploadForm;
