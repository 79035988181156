export const extractDateAndTime = (fullDate: string): { date: string; time: string } => {
  const [date, time] = fullDate.split(',');

  // eslint-disable-next-line prefer-const
  let [day, month, year] = date.split(':');

  if (day.length === 1) day = `0${day}`;
  if (month.length === 1) month = `0${month}`;

  return { date: `${year}-${month}-${day}`, time };
};
