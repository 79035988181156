import React, { SVGProps } from 'react';

const IconAiCoin: React.FC<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <rect x='1' y='1' width='22' height='22' rx='11' stroke='currentColor' strokeWidth='2' />
      <path
        d='M12 6L10.7253 9.87533C10.6601 10.0737 10.5492 10.2539 10.4016 10.4016C10.2539 10.5492 10.0737 10.6601 9.87533 10.7253L6 12L9.87533 13.2747C10.0737 13.3399 10.2539 13.4508 10.4016 13.5984C10.5492 13.7461 10.6601 13.9263 10.7253 14.1247L12 18L13.2747 14.1247C13.3399 13.9263 13.4508 13.7461 13.5984 13.5984C13.7461 13.4508 13.9263 13.3399 14.1247 13.2747L18 12L14.1247 10.7253C13.9263 10.6601 13.7461 10.5492 13.5984 10.4016C13.4508 10.2539 13.3399 10.0737 13.2747 9.87533L12 6Z'
        fill='currentColor'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.33398 6V8.66667M16.666 15.3333V17.9999M6 7.33325H8.66667M15.334 16.6667H18.0007'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default IconAiCoin;
