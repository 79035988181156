import { FC, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import emptyAvatar from 'assets/images/png/emptyAvatar.png';
import { IconClose, IconScoreCoin } from 'components/atoms/icons';
import Input from 'components/atoms/Input';
import Button from 'components/atoms/Button';
import styles from './AiTeacher.module.css';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { apiUsers } from 'api';
import { useMutation, useQuery } from 'react-query';
import { handleError } from 'helpers/handleError';
import { useGetUserInfoQuery } from 'hooks/queries';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from './model';
import { BalanceHistory } from './ui';
import { SEARCH_PARAMS } from 'pages/DealNotificationsPage/const';

const AiTeacher: FC = () => {
  const navigate = useNavigate();
  const { id: userId } = useParams();
  const location = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const page = useMemo(() => {
    return Number(searchParams.get(SEARCH_PARAMS.PAGE)) || DEFAULT_PAGE;
  }, [searchParams]);

  const { data: user, isLoading: userLoading } = useGetUserInfoQuery(String(userId));

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty }
  } = useForm({
    defaultValues: {
      coins: ''
    },
    mode: 'onSubmit'
  });

  const { data: userSubscriptionData, refetch: userSubscriptionRefetch } = useQuery(
    ['ai-teacher-subscriptions', userId],
    async () => await apiUsers.getUserAiTeacherSubscription(String(userId)),
    {
      enabled: !!userId,
      onError(error) {
        handleError(error);
      }
    }
  );

  const {
    data: userBalanceHistoryData,
    isLoading: userBalanceHistoryLoading,
    refetch: userBalanceHistoryRefetch
  } = useQuery(
    ['ai-teacher-balance-history', userId, page],
    async () => await apiUsers.getUserBalanceHistory(String(userId), page, DEFAULT_PER_PAGE),
    {
      enabled: !!userId,
      onError(error) {
        handleError(error);
      }
    }
  );

  const userCoinsBalance = userSubscriptionData?.coins || 0;

  const onCancel = () => {
    navigate(`users:${userId}/statistics`);
  };
  const addCoinsToUser = useMutation(
    async (coins: number) => {
      if (!userId) {
        throw new Error('User ID отсутствует');
      }
      return await apiUsers.addAiTeacherCoinsToUser(userId, { coins });
    },
    {
      onSuccess: () => {
        reset();
      },
      onError: error => {
        handleError(error);
      },
      onSettled: () => {
        userSubscriptionRefetch();
        userBalanceHistoryRefetch();
      }
    }
  );

  const onSubmit = async (data: any) => {
    const coins = Number(data.coins);
    if (!coins || coins <= 0) {
      return;
    }

    addCoinsToUser.mutate(coins);
  };

  if (userLoading) return null;
  if (!user) return <h2>Не удалось загрузить данные пользователя</h2>;

  return (
    <div className='flex h-full grow flex-col'>
      <button className='flex select-none items-center gap-[16px] self-start' onClick={onCancel}>
        <IconClose className='h-[24px] w-[24px] text-[#5770F3]' />
        <span className='text-[16px] leading-[16px] text-[#20233A]'>Отменить</span>
      </button>

      <form className='flex flex-col' onSubmit={handleSubmit(onSubmit)}>
        <header className='mb-[24px] flex items-center justify-between'>
          <h2 className='flex h-[88px] pt-[30px] text-[30px] font-[700] leading-[36px] text-[#20233A]'>
            AI-учитель
          </h2>
          <div className={styles.scoreAmount}>
            <span>{userCoinsBalance}</span>
            <IconScoreCoin />
          </div>
        </header>

        <div className='mb-[32px] flex items-center gap-[16px]'>
          <div className='h-[100px] w-[100px] overflow-hidden rounded-[12px]'>
            <img className='h-full w-full' src={emptyAvatar} alt='Avatar placeholder' />
          </div>

          <div className='flex flex-col gap-[8px]'>
            <p
              title={user.name}
              className={
                'text-[22px] font-[700] leading-[26px] ' +
                'overflow-hidden whitespace-nowrap text-ellipsis'
              }
            >
              {user.name}
            </p>
            <p
              title={user.roles.join(', ')}
              className={
                'text-[16px] font-[500] leading-[19px] text-[#20233A] ' +
                'overflow-hidden whitespace-nowrap text-ellipsis'
              }
            >
              {user.roles.join(', ')}
            </p>
            <p
              title={user.email}
              className={
                'text-[16px] leading-[19px] text-[#5770F3] ' +
                'overflow-hidden whitespace-nowrap text-ellipsis'
              }
            >
              {user.email}
            </p>
          </div>
        </div>

        <h3 className='mb-[10px] text-[16px] font-[500] leading-[19px] text-[#71798F]'>
          Количество монет
        </h3>

        <div className={styles.fields}>
          <div className='w-[330px]'>
            <Input
              type='text'
              name='coins'
              control={control}
              rules={{
                required: { value: true, message: 'Поле обязательно для заполнения' },
                maxLength: { value: 64, message: 'Максимальная длина 64 символа' },
                validate: {
                  isPositive: (value: string) => {
                    return /^\d+$/.test(value.trim()) || 'Введите корректное число';
                  }
                }
              }}
            />
          </div>
          <Button
            type='submit'
            variant='primary'
            title='Зачислить'
            className='w-[140px]'
            isDisabled={!isDirty}
            isLoading={false}
          />
        </div>

        <BalanceHistory
          userBalanceHistoryData={userBalanceHistoryData}
          isLoading={userBalanceHistoryLoading}
        />
      </form>
    </div>
  );
};

export default AiTeacher;
