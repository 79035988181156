import { FC } from 'react';
import styles from './AddAiCoinsModal.module.css';
import { IconClose } from 'components/atoms/icons';
import Modal from 'components/atoms/Modal';
import { User } from 'models';
import { AddAiCoinsForm } from '../AddAiCoinsForm';

type AddAiCoinsModalProps = {
  isOpen: boolean;
  user: User | null;
  onClose: () => void;
};

const AddAiCoinsModal: FC<AddAiCoinsModalProps> = ({ isOpen, onClose, user }) => {
  return isOpen ? (
    <Modal className='z-20' onClose={onClose}>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.title}>Пополнить баланс AI-учитель</div>

          <button className={styles.buttonClose} onClick={onClose}>
            <IconClose color='#71798F' />
          </button>
        </div>
        <AddAiCoinsForm user={user} />
      </div>
    </Modal>
  ) : null;
};

export default AddAiCoinsModal;
