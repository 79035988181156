import { apiUsers } from 'api';
import { handleError } from 'helpers/handleError';
import { useMutation, useQuery } from 'react-query';
import { FieldValues, useForm } from 'react-hook-form';
import { FC } from 'react';
import { User } from 'models';
import styles from './AddAiCoinsForm.module.css';
import Input from 'components/atoms/Input';
import { IconScoreCoin } from 'components/atoms/icons';
import Button from 'components/atoms/Button';

type AddAiCoinsFormProps = { user?: User | null };

export const AddAiCoinsForm: FC<AddAiCoinsFormProps> = props => {
  const { user } = props;

  const userId = user?.id || '';

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty }
  } = useForm<FieldValues>({ mode: 'onSubmit' });

  const { data: userSubscriptionData, refetch: userSubscriptionRefetch } = useQuery(
    ['ai-teacher-subscriptions', userId],
    async () => await apiUsers.getUserAiTeacherSubscription(userId),
    {
      enabled: !!userId,
      onError(error) {
        handleError(error);
      }
    }
  );

  const currentCoinsAmount = userSubscriptionData?.coins || 0;

  const addCoinsToUserMutation = useMutation(
    async (coins: number) => {
      if (!userId) {
        throw new Error('User ID отсутствует');
      }
      return await apiUsers.addAiTeacherCoinsToUser(userId, { coins });
    },
    {
      onSuccess: () => {
        reset();
      },
      onError: error => {
        handleError(error);
      },
      onSettled: () => {
        userSubscriptionRefetch();
      }
    }
  );

  const onSubmit = async (data: FieldValues) => {
    const coins = Number(data.coins);
    if (!coins || coins <= 0) {
      return;
    }

    addCoinsToUserMutation.mutate(coins);
  };

  return (
    <>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.fields}>
          <Input
            type='text'
            name='coins'
            control={control}
            rules={{
              required: { value: true, message: 'Поле обязательно для заполнения' },
              maxLength: { value: 64, message: 'Максимальная длина 64 символа' },
              validate: {
                isPositive: (value: string) => {
                  return /^\d+$/.test(value.trim()) || 'Введите корректное число';
                }
              }
            }}
            placeholder='Количество монет'
          />
          <Button
            type='submit'
            variant='primary'
            title='Зачислить'
            className='w-[198px]'
            isDisabled={!isDirty}
          />
        </div>
      </form>
      <div className={styles.footer}>
        <div className={styles.score}>Текущий баланс</div>
        <div className={styles.scoreAmount}>
          <span>{currentCoinsAmount}</span>
          <IconScoreCoin />
        </div>
      </div>
    </>
  );
};
