import Input from 'components/atoms/Input';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

// import defaultLessonImagePNG from 'assets/images/png/lesson-placeholder.png';

import { CreateLessonDto } from 'api/api-courses';
import { FORM_ERROR_MESSAGES } from 'consts';
// import { dataUrlToFile } from 'utils';
import styles from './AddLessonForm.module.css';

import Button from 'components/atoms/Button';
import { IconNext, IconUpload } from 'components/atoms/icons';
import Modal from 'components/atoms/Modal';
import ImageUploadForm from 'components/organisms/ImageUploadForm';
import { useAddLessonMutation } from 'hooks/lesson/mutations';

type FormValues = {
  title: string;
  subtitle: string;
};

interface AddLessonFormProps {
  courseId?: string;
  moduleId?: string;
  isSquare?: boolean;
  order: number;
}

const AddLessonForm: FC<AddLessonFormProps> = ({ courseId, moduleId, order, isSquare = false }) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty }
  } = useForm<FormValues>({ mode: 'onSubmit' });

  const [image, setImage] = useState<File | null>(null);

  const [imagePreviewUrl, setImagePreviewUrl] = useState<string | null>(null);
  const [isImageUploadFormModalActive, setImageUploadFormModalActive] = useState<boolean>(false);

  useEffect(() => {
    if (!image) {
      return;
    }

    const objectUrl = URL.createObjectURL(image);
    setImagePreviewUrl(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [image]);

  const { mutate: addLessonMutate, isLoading: addLessonLoading } = useAddLessonMutation();

  const handleReset = async () => {
    reset();
    setImage(null);
    setImagePreviewUrl(null);
  };

  const onSubmit = async (data: FormValues) => {
    const { title, subtitle } = data;

    const imageFile = image;

    const createLessonData: CreateLessonDto = {
      courseId,
      moduleId,
      body: {
        title: title.trim(),
        subtitle: subtitle.trim(),
        image: imageFile,
        order
      }
    };

    addLessonMutate(createLessonData, {
      onSuccess: handleReset
    });
  };

  return (
    <>
      <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.image} onClick={() => setImageUploadFormModalActive(true)}>
          {imagePreviewUrl ? (
            <img src={imagePreviewUrl} alt={imagePreviewUrl} />
          ) : (
            <IconUpload color='#8C9AAF' />
          )}
        </div>

        <Input
          type='text'
          name='subtitle'
          variant='dark'
          width='big'
          label='Подзаголовок'
          control={control}
          rules={{
            required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
            maxLength: { value: 64, message: 'Максимальная длина 64 символа' },
            validate: {
              doesntConsistOfSpaces: (value: any) => {
                return !!value.trim() ? true : FORM_ERROR_MESSAGES.DOESNT_CONSIST_OF_SPACES;
              }
            }
          }}
          loading={addLessonLoading}
          containerClassName='grow-[1]'
        />

        <Input
          type='text'
          name='title'
          variant='dark'
          width='big'
          label='Название урока'
          control={control}
          rules={{
            required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
            maxLength: { value: 64, message: 'Максимальная длина 64 символа' },
            validate: {
              doesntConsistOfSpaces: (value: any) => {
                return !!value.trim() ? true : FORM_ERROR_MESSAGES.DOESNT_CONSIST_OF_SPACES;
              }
            }
          }}
          loading={addLessonLoading}
          containerClassName='grow-[2]'
        />

        <Button
          type='submit'
          variant='primary'
          title='Добавить'
          icon={<IconNext />}
          iconPosition='right'
          isDisabled={!isDirty}
          isLoading={addLessonLoading}
        />

        {(isDirty || image) && (
          <Button
            type='reset'
            variant='secondary'
            title='Отменить'
            onClick={handleReset}
            isDisabled={!isDirty || addLessonLoading}
          />
        )}
      </form>

      {isImageUploadFormModalActive && (
        <Modal onClose={() => setImageUploadFormModalActive(false)}>
          <ImageUploadForm
            isSquare={isSquare}
            image={image}
            setImage={(image: File) => setImage(image)}
            onCancel={() => setImageUploadFormModalActive(false)}
          />
        </Modal>
      )}
    </>
  );
};

export default AddLessonForm;
