import { FC, useMemo } from 'react';
import { Navigate, Route, Routes, useMatch } from 'react-router-dom';

import {
  AddSubscriptions,
  AiTeacher,
  Sidebar,
  UpdateUser,
  UserStatistics,
  UserSubscriptions
} from './components';

const UserPage: FC = () => {
  const isAddSubscriptions = useMatch('users/:id/subscriptions/add');
  const isUpdateUser = useMatch('users/:id/edit');
  const isAiTeacher = useMatch('users/:id/ai-teacher');

  const hideSidebar = useMemo(() => {
    return isAddSubscriptions || isUpdateUser || isAiTeacher;
  }, [isAddSubscriptions, isUpdateUser, isAiTeacher]);

  return (
    <div className='flex h-full flex-col'>
      <div className='flex h-full gap-[72px]'>
        {!hideSidebar && <Sidebar />}

        <div className={`flex ${hideSidebar ? 'w-full' : 'w-[calc(100%-372px)]'}`}>
          <Routes>
            <Route path='/statistics' element={<UserStatistics />} />
            <Route path='/subscriptions' element={<UserSubscriptions />} />
            <Route path='/subscriptions/add' element={<AddSubscriptions />} />
            <Route path='/ai-teacher/' element={<AiTeacher />} />
            <Route path='/edit' element={<UpdateUser />} />
            <Route path='*' element={<Navigate to='statistics' replace />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default UserPage;
