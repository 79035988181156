/* eslint-disable react-hooks/exhaustive-deps */

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import RSelect, { SingleValue } from 'react-select';

import { apiUsers } from 'api/api-users';
import { DEFAULT_USERS_PER_PAGE, USERS_TABLE_HEADERS } from 'consts';
import { handleError } from 'helpers/handleError';
import { useDebounce } from 'hooks';
import { useDeleteUserMutation } from 'hooks/user';
import { IOption, User } from 'models';
import { resolveUserStatus, sortByMainRole } from 'utils';
import styles from './UsersPage.module.css';
import {
  DEFAULT_PAGE,
  SEARCH_PARAMS,
  filterRoleOptions,
  filterSelectStyles,
  usersPerPageOptions
} from './const';

import ButtonAdd from 'components/atoms/ButtonAdd';
import ConfirmationModal from 'components/atoms/ConfirmationModal';
import SearchField from 'components/atoms/SearchField/SearchField';
import { IconBookPlus, IconClose, IconPen, IconTrash, IconAiCoin } from 'components/atoms/icons';
import SortBottomIcon from 'components/atoms/icons/SortBottomIcon';
import SortDisabledIcon from 'components/atoms/icons/SortDisabledIcon';
import SortTopIcon from 'components/atoms/icons/SortTopIcon';
import BePagintaion from 'components/molecules/BePagination/BePagination';
import { SkeletonRows } from './components';
import AddAiCoinsModal from 'components/organisms/AddAiCoins';

const UsersPage: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { mutate: deleteUserMutate, isLoading: deleteUserLoading } = useDeleteUserMutation();
  const [confirmationModalActive, setConfirmationModalActive] = useState(false);
  const [addAiCoinsModalActive, setAddAiCoinsModalActive] = useState(false);
  const [userToDelete, setUserToDelete] = useState<User | null>(null);
  const [userToAddCoins, setUserToAddCoins] = useState<User | null>(null);

  const searchValue = useMemo(() => {
    return searchParams.get(SEARCH_PARAMS.SEARCH) || '';
  }, [searchParams]);
  const selectedRole = useMemo(() => {
    return searchParams.get(SEARCH_PARAMS.ROLE) || '';
  }, [searchParams]);
  const sortBy = useMemo(() => {
    return searchParams.get(SEARCH_PARAMS.SORT_BY) || '';
  }, [searchParams]);
  const usersPerPage = useMemo(() => {
    return Number(searchParams.get(SEARCH_PARAMS.USERS_PER_PAGE)) || DEFAULT_USERS_PER_PAGE;
  }, [searchParams]);
  const currentPage = useMemo(() => {
    return Number(searchParams.get(SEARCH_PARAMS.PAGE)) || DEFAULT_PAGE;
  }, [searchParams]);

  const [value, setValue] = useState<string>(() => searchValue || '');
  const debouncedSearchValue = useDebounce(value, 500);

  useEffect(() => {
    if (debouncedSearchValue) {
      searchParams.set(SEARCH_PARAMS.PAGE, String(DEFAULT_PAGE));
      searchParams.set(SEARCH_PARAMS.SEARCH, debouncedSearchValue);
      setSearchParams(searchParams);
    } else {
      if (searchParams.has(SEARCH_PARAMS.SEARCH)) {
        searchParams.delete(SEARCH_PARAMS.SEARCH);
        setSearchParams(searchParams);
      }
    }
  }, [debouncedSearchValue]);

  useEffect(() => {
    setValue(searchValue);
  }, [searchValue]);

  const handleResetFilters = useCallback(() => {
    if (!Array.from(searchParams).length) return;

    setValue('');
    searchParams.delete(SEARCH_PARAMS.SEARCH);
    searchParams.delete(SEARCH_PARAMS.ROLE);
    searchParams.delete(SEARCH_PARAMS.USERS_PER_PAGE);
    searchParams.delete(SEARCH_PARAMS.SORT_BY);
    searchParams.delete(SEARCH_PARAMS.PAGE);
    setSearchParams(searchParams);
  }, [searchParams]);

  const {
    data: usersData,
    isLoading: usersDataLoading,
    refetch: refetchUsers
  } = useQuery(
    ['users/all', usersPerPage, currentPage, selectedRole, searchValue, sortBy],
    async () =>
      await apiUsers.getUsers({
        page: currentPage,
        itemsPerPage: usersPerPage,
        roles: selectedRole ? [selectedRole] : [],
        letters: searchValue,
        sortBy
      }),
    {
      onError(error) {
        handleError(error);
      }
    }
  );

  const handleAddSubscriptionsToUser = (userId: string) => {
    navigate(`/users/${userId}/subscriptions/add`);
  };

  const handleAddAiCoinsModal = (user: User) => {
    setAddAiCoinsModalActive(!addAiCoinsModalActive);
    setUserToAddCoins(user);
  };

  const deleteUser = () => {
    if (userToDelete) {
      deleteUserMutate(userToDelete.id, {
        onSuccess: () => {
          setConfirmationModalActive(false);
          refetchUsers();
        }
      });
    }
  };

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <p className='text-[36px] font-[700] leading-[44px] text-[#2F324F]'>Пользователи</p>

        <ButtonAdd
          variant='dark'
          size='big'
          type='button'
          title='Создать пользователя'
          onClick={() => navigate('/users/create')}
        />
      </header>

      <div className={styles.table}>
        <div className={styles.table__filters}>
          <SearchField
            className='max-w-[360px]'
            placeholder={'Поиск по имени/email'}
            value={value}
            setState={setValue}
            type={'text'}
            id={'title'}
          />

          <RSelect
            value={filterRoleOptions.find(({ value }) => value === selectedRole)}
            classNamePrefix='custom-select'
            styles={filterSelectStyles}
            placeholder={'Все роли'}
            options={filterRoleOptions}
            onChange={(newValue: SingleValue<unknown>) => {
              searchParams.set(SEARCH_PARAMS.PAGE, String(DEFAULT_PAGE));
              const role = (newValue as IOption).value;
              searchParams.set(SEARCH_PARAMS.ROLE, role);
              setSearchParams(searchParams);
            }}
            isSearchable={false}
          />

          <button type='button' className={styles.buttonResetFilters} onClick={handleResetFilters}>
            <IconClose color='#71798F' />
            Сбросить все
          </button>

          <RSelect
            value={usersPerPageOptions.find(({ value }) => value === usersPerPage)}
            classNamePrefix='custom-select'
            styles={filterSelectStyles}
            placeholder='По 10'
            options={usersPerPageOptions}
            onChange={(newValue: SingleValue<unknown>) => {
              searchParams.set(SEARCH_PARAMS.PAGE, String(DEFAULT_PAGE));
              const usersPerPage = (newValue as IOption).value;
              searchParams.set(SEARCH_PARAMS.USERS_PER_PAGE, usersPerPage);
              setSearchParams(searchParams);
            }}
            isSearchable={false}
          />
        </div>

        <div className={styles.table__headers}>
          <button
            className={`${styles.table__header} ${styles.table__header_name}`}
            onClick={() => {
              searchParams.set(SEARCH_PARAMS.PAGE, String(DEFAULT_PAGE));

              if (sortBy === 'name-asc') {
                searchParams.set(SEARCH_PARAMS.SORT_BY, 'name-desc');
              } else if (sortBy === 'name-desc') {
                searchParams.delete(SEARCH_PARAMS.SORT_BY);
              } else {
                searchParams.set(SEARCH_PARAMS.SORT_BY, 'name-asc');
              }
              setSearchParams(searchParams);
            }}
          >
            {USERS_TABLE_HEADERS[0]}

            {sortBy === 'name-desc' ? (
              <SortBottomIcon />
            ) : sortBy === 'name-asc' ? (
              <SortTopIcon />
            ) : (
              <SortDisabledIcon />
            )}
          </button>

          <button
            className={`${styles.table__header} ${styles.table__header_email}`}
            onClick={() => {
              searchParams.set(SEARCH_PARAMS.PAGE, String(DEFAULT_PAGE));

              if (sortBy === 'email-asc') {
                searchParams.set(SEARCH_PARAMS.SORT_BY, 'email-desc');
              } else if (sortBy === 'email-desc') {
                searchParams.delete(SEARCH_PARAMS.SORT_BY);
              } else {
                searchParams.set(SEARCH_PARAMS.SORT_BY, 'email-asc');
              }
              setSearchParams(searchParams);
            }}
          >
            {USERS_TABLE_HEADERS[1]}

            {sortBy === 'email-desc' ? (
              <SortBottomIcon />
            ) : sortBy === 'email-asc' ? (
              <SortTopIcon />
            ) : (
              <SortDisabledIcon />
            )}
          </button>

          <span className={`${styles.table__header} ${styles.table__header_role}`}>
            {USERS_TABLE_HEADERS[2]}
          </span>

          <span className={`${styles.table__header} ${styles.table__header_status}`}>
            {USERS_TABLE_HEADERS[3]}
          </span>

          <span className={`${styles.table__header} ${styles.table__header_actions}`}>
            {USERS_TABLE_HEADERS[4]}
          </span>
        </div>

        <div className={styles.table__rows}>
          {usersData && !usersDataLoading ? (
            <>
              {usersData.data.length > 0 ? (
                <>
                  {usersData.data.map((user, i) => {
                    return (
                      <div
                        className={`${styles.table__row} ${
                          i % 2 === 1 ? styles.table__row_even : ''
                        }`}
                        key={user.id}
                      >
                        <button
                          type='button'
                          className={`${styles.table__cell} ${styles.table__cell_name}`}
                          onClick={() => navigate(`/users/${user.id}`)}
                        >
                          <span>{user.name}</span>
                        </button>

                        <div className={`${styles.table__cell} ${styles.table__cell_email}`}>
                          <span>{user.email}</span>
                        </div>

                        <div
                          title={sortByMainRole(user.roles).join(', ')}
                          className={`${styles.table__cell} ${styles.table__cell_role}`}
                        >
                          <span>{sortByMainRole(user.roles).join(', ')}</span>
                        </div>

                        <div className={`${styles.table__cell} ${styles.table__cell_status}`}>
                          <span>{resolveUserStatus(user)}</span>
                        </div>

                        <div className={`${styles.table__cell} ${styles.table__cell_actions}`}>
                          <button onClick={() => handleAddSubscriptionsToUser(user.id)}>
                            <IconBookPlus color='#71798F' />
                          </button>

                          <button onClick={() => handleAddAiCoinsModal(user)}>
                            <IconAiCoin color='#71798F' />
                          </button>

                          <button onClick={() => navigate(`/users/${user.id}/edit`)}>
                            <IconPen color='#71798F' />
                          </button>

                          <button
                            onClick={() => {
                              setUserToDelete(user);
                              setConfirmationModalActive(true);
                            }}
                          >
                            <IconTrash color='#71798F' />
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <span>Результатов не найдено</span>
              )}
            </>
          ) : (
            <SkeletonRows />
          )}
        </div>

        <div className={styles.table__pagination}>
          {usersData && usersData.pagination.totalItems > usersPerPage && (
            <BePagintaion
              pagination={usersData.pagination}
              setCurrentPage={page => {
                searchParams.set(SEARCH_PARAMS.PAGE, String(page));
                setSearchParams(searchParams);
              }}
            />
          )}
        </div>
      </div>

      {confirmationModalActive && (
        <ConfirmationModal
          title={
            <>
              Удалить пользователя <br />
              {userToDelete?.name}?
            </>
          }
          text={
            <>
              Пользователь будет удален <b>навсегда</b> и <b>безвозвратно</b>
            </>
          }
          isDelete={true}
          confirmButtonText='Удалить'
          onConfirm={deleteUser}
          onClose={() => setConfirmationModalActive(false)}
          isLoading={deleteUserLoading}
        />
      )}

      <AddAiCoinsModal
        isOpen={addAiCoinsModalActive}
        onClose={() => setAddAiCoinsModalActive(false)}
        user={userToAddCoins}
      />
    </div>
  );
};

export default UsersPage;
